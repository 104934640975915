import './ContactUs.css';

function ContactUs() {
  console.log('!!!!');
  return (
    <div className="ContactUs">
      <h1>Contact Us</h1>
      <p>
        <b>Advertising</b> - Please email{' '}
        <a href="mailto:advertising@dataskeptic.com">
          advertising@dataskeptic.com
        </a>
      </p>
      <p>
        <b>General Comments</b> - Please tweet to{' '}
        <a href="https://twitter.com/dataskeptic">@dataskeptic</a>
      </p>
      <p>
        <b>Suggest a guest</b> - Thanks, but no thanks. We got this.
      </p>
    </div>
  );
}

export default ContactUs;
