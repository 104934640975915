import './Header.css';
import ScrollToTop from './ScrollToTop';

function Header() {
  return (
    <div className="jumbotron text-center">
      <ScrollToTop />
    </div>
  );
}

export default Header;
