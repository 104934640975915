import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import ShippingDetail from './ShippingDetail';
import Order from './Order';
import './index.css';

const OrderSummary = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <div className="Store">
      <Container>
        <Row>
          <div class="container-fluid">
            <h2 class="card-title space">Order Summary</h2>
            <div class="row">
              <div class="col-md-6">
                <ShippingDetail />
              </div>
              <div class="col-md-6">
                <Order />
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default OrderSummary;
