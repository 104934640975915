import './Guest.css';
var MarkdownIt = require('markdown-it')

import { Container, Row, Col } from 'reactstrap';

function Guest(props) {
  const guest = props.guest;
  if (guest === undefined || guest === null || guest['name'] === undefined) {
    return <div></div>;
  }
  const name = guest.name;
  const bio = guest.bio;
  const twitter = guest.twitter;
  var image_key = guest.image_key;
  var image_url = undefined;
  if (image_key && image_key.startsWith('user/')) {
    const i = image_key.indexOf('pub/')
    image_url = process.env.REACT_APP_API_URL + image_key.substring(i+3)
  }
          // <Col xs="3">
          //   <img class="GuestImage" src={image_url} />
          // </Col>
  const turl = `https://twitter.com/${twitter}`
  const md = new MarkdownIt();
  const html_bio = "<i>" + md.render(bio) + "</i>";
  console.log({html_bio})
  return (
    <div className="Guest">
      <Container>
        <Row>
          <Col xs="12">
            {twitter && (
              <div>
                <h4>{name}</h4>
                <div className="GuestTwitter">
                  <a href={turl}>{twitter}</a>
                </div>
              </div>
            )}
            <p>
            <div dangerouslySetInnerHTML={{ __html: html_bio }} />
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Guest;
