import './Members.css';
import { Helmet } from 'react-helmet';

function Members() {
  return (
    <div className="Members">
      <Helmet>
        <title>Data Skeptic: Membership</title>
      </Helmet>
      <h2>Data Skeptic Membership</h2>
      <div class="alert alert-info text-center" role="alert">
        <p>
          Members portal login:{' '}
          <a href="https://plus.dataskeptic.com">Data Skeptic PLUS</a>
        </p>
        Data Skeptic members, we're getting our backend in order. Please reach
        out to{' '}
        <a href="mailto:vanessa@dataskeptic.com">vanessa@dataskeptic.com</a>{' '}
        with any questions, issues, or to cancel your membership. Thanks for
        your patience in this time of reinvention.
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <h3>
            <span class="glyphicon glyphicon-info-sign"></span> Current benefits
          </h3>
          <ul>
            <li>Making Data Skeptic possible.</li>
            <li>Special events and promotions.</li>
          </ul>
        </div>
        <div class="col-md-6">
          <h3>
            <span class="glyphicon glyphicon-link"></span> Things we are working
            on for members
          </h3>
          <ul>
            <li>A private analytics dashboard about listenership</li>
            <li>Access to secret swag</li>
            <li>Private Slack channel for BTS discussion</li>
            <li>Live events?</li>
            <li>Discounts on merch</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Members;
