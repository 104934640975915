import './Cart.css';
import { useState } from 'react';
import CartItem from './CartItem';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  ListGroup,
  ListGroupItem,
  Alert,
} from 'reactstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga';
import { updateShipping } from '../redux/cart';

const emailReg = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

function Cart() {
  const [promoCode, setPromoCode] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const cartItems = cart.items;
  if (cartItems === undefined) {
    return (
      <div className="Cart">Loading</div>
    )
  }
  const discount = cart.discount;
  const subtotal = cart.subtotal;
  const shipping = cart.shipping;
  const email = cart.email;
  const shipping_name = cart.shipping_name;
  const shipping_address = cart.shipping_address;
  const shipping_city = cart.shipping_city;
  const shipping_postal_code = cart.shipping_postal_code;
  const shipping_country = cart.shipping_country;
  const shipping_region = cart.shipping_region;

  const beginCheckout = () => {
    // TODO: save to DynamoDB owner=store, object_id = owner.username
    ReactGA.event({
      category: 'User',
      action: 'Checkout',
    });
    history.replace('/checkout');
  };

  const selectRegion = (val) => {
    dispatch(updateShipping({ shipping_region: val }));
  };
  console.log({cart})

  const tax = parseInt(cart.tax * 100) / 100;
  const total = cart.total;
  const country = cart.country;
  const region = cart.region;
  if (cartItems.length === 0) {
    return (
      <div>
        <h2>Your cart is empty.</h2>
        <p>You need to put something in it.</p>
      </div>
    );
  }
  const canCheckout =
    email !== '' &&
    emailReg.test(email) &&
    shipping_name !== '' &&
    shipping_address !== '' &&
    shipping_city !== '' &&
    shipping_postal_code !== '' &&
    shipping_country !== '' &&
    shipping_region !== '';
  return (
    <div className="Cart">
      <div className="CartItems">
        {cartItems.map(function (cartItem, idx) {
          return <CartItem key={idx} cartItem={cartItem} />;
        })}
      </div>
      <div className="MailingAddressDiv">
        <Container>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label className="MailingLabel">Recipient Email</Label>
                <Input
                  className="MailingInput"
                  value={email}
                  onChange={(e) =>
                    dispatch(updateShipping({ email: e.target.value }))
                  }
                  invalid={email && !emailReg.test(email)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label className="MailingLabel">Recipient</Label>
                <Input
                  className="MailingInput"
                  value={shipping_name}
                  onChange={(e) =>
                    dispatch(updateShipping({ shipping_name: e.target.value }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label className="MailingLabel">Address</Label>
                <Input
                  className="MailingInput"
                  value={shipping_address}
                  onChange={(e) =>
                    dispatch(
                      updateShipping({ shipping_address: e.target.value }),
                    )
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label className="MailingLabel">City</Label>
                <Input
                  className="MailingInput"
                  value={shipping_city}
                  onChange={(e) =>
                    dispatch(updateShipping({ shipping_city: e.target.value }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label className="MailingLabel">Country</Label>
                <CountryDropdown
                  className="MailingInput form-select"
                  value={shipping_country}
                  onChange={(val) => {
                    dispatch(updateShipping({ shipping_country: val }));
                  }}
                  priorityOptions={['US', 'CA', 'GB']}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label className="MailingLabel">State / Province</Label>
                <RegionDropdown
                  className="MailingInput form-select"
                  country={shipping_country}
                  value={shipping_region}
                  onChange={selectRegion}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label className="MailingLabel">Postal Code</Label>
                <Input
                  className="MailingInput"
                  value={shipping_postal_code}
                  onChange={(e) =>
                    dispatch(
                      updateShipping({ shipping_postal_code: e.target.value }),
                    )
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="PromoDiv">
        <Container>
          <Row>
            <Col xs="4">
              <Label className="PromoCode">Promo Code:</Label>
            </Col>
            <Col xs="6">
              <Input
                className="MailingInput"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
            </Col>
            <Col xs="2">
              <Button
                onClick={() =>
                  alert('Could not verify.  Please contact support.')
                }
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="ReceiptDiv">
        <Container>
          <ListGroup>
            <ListGroupItem>
              <Row>
                <Col xs="8">
                  <span className="reciept-line">Discount</span>
                </Col>
                <Col xs="4">{discount}</Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem>
              <Row>
                <Col xs="8">
                  <span className="reciept-line">Sub-Total</span>
                </Col>
                <Col xs="4">{subtotal}</Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem>
              <Row>
                <Col xs="8">
                  <span className="reciept-line">Shipping</span>
                </Col>
                <Col xs="4">{shipping}</Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem>
              <Row>
                <Col xs="8">
                  <span className="reciept-line">Tax</span>
                </Col>
                <Col xs="4">{tax}</Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem>
              <Row>
                <Col xs="8">
                  <span className="reciept-line">Total</span>
                </Col>
                <Col xs="4">{total}</Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Container>
      </div>
      <Button
        disabled={!canCheckout}
        onClick={() => beginCheckout()}
        className="ButtonGoToCheckout"
      >
        Go to checkout
      </Button>
    </div>
  );
}

export default Cart;
