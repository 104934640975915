import './ListenOn.css';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

function ListenOn(props) {
  return (
    <div className="ListenOn">
        <a href="https://podcasts.apple.com/us/podcast/data-skeptic/id890348705"><img className="HListenOnImg" src="https://s3.amazonaws.com/dataskeptic.com/images/blogpage/apple-podcasts.png" /></a>
        <a href="https://open.spotify.com/show/1BZN7H3ikovSejhwQTzNm4"><img className="HListenOnImg" src="https://s3.amazonaws.com/dataskeptic.com/images/blogpage/spotify.png" /></a>

        <br/>

        <a href="https://castbox.fm/channel/Data-Skeptic-id1207095"><img alt="listen on castbox.fm" className="distro" src="http://s3.amazonaws.com/dataskeptic.com/images/homepage/badges/cast-box.png" /></a>
        <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9kYXRhc2tlcHRpYy5saWJzeW4uY29tL3Jzcw=="><img alt="listen on google podcasts" className="distro" src="http://s3.amazonaws.com/dataskeptic.com/images/homepage/badges/google-podcasts.png" /></a>
        <a href="https://player.fm/series/data-skeptic"><img alt="listen on player.fm" className="distro" src="http://s3.amazonaws.com/dataskeptic.com/images/homepage/badges/player-fm.png" /></a>
        <a href="https://pca.st/dataskeptic"><img alt="listen on pocketcasts" className="distro" src="http://s3.amazonaws.com/dataskeptic.com/images/homepage/badges/pocketcasts.png" /></a>
        <a href="https://podcastaddict.com/podcast/505621"><img alt="listen on podcast addict" className="distro" src="http://s3.amazonaws.com/dataskeptic.com/images/homepage/badges/podcast-addict.png" /></a>
        <a href="https://tunein.com/podcasts/Science/Data-Skeptic-p618623/"><img alt="listen on tunin" className="distro" src="http://s3.amazonaws.com/dataskeptic.com/images/homepage/badges/tunein.jpg" /></a>
        <a href="https://music.amazon.com/podcasts/2e5408fd-6900-42c2-b1bc-c4252dcb7a87/Data-Skeptic"><img alt="listen on Amazon Music" className="distro" src="http://s3.amazonaws.com/dataskeptic.com/images/homepage/badges/amazon-music.png" /></a>
        <a href="https://www.stitcher.com/podcast/data-skeptic-podcast/the-data-skeptic-podcast"><img alt="listen on Stitcher" className="distro" src="http://s3.amazonaws.com/dataskeptic.com/images/homepage/badges/stitcher.png" /></a>
    </div>
  );
}

export default ListenOn;
