import axios from './index';

export const getStoreAPI = async () => {
  try {
    const result = await axios({
      method: 'get',
      url: '/store/dataskeptic/products',
    });
    return result.data.products;
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const postOrderAPI = async (token, addresses, cart) => {
  try {
    const result = await axios({
      method: 'POST',
      url: '/store/dataskeptic/order',
      data: {
        token,
        addresses,
        cart
      }
    });
    console.log({result})
    return result
  } catch (ex) {
    console.log(ex);
    return {}
  }
}

const us_state_to_abbrev = {
    "Alabama": "AL",
    "Alaska": "AK",
    "Arizona": "AZ",
    "Arkansas": "AR",
    "California": "CA",
    "Colorado": "CO",
    "Connecticut": "CT",
    "Delaware": "DE",
    "Florida": "FL",
    "Georgia": "GA",
    "Hawaii": "HI",
    "Idaho": "ID",
    "Illinois": "IL",
    "Indiana": "IN",
    "Iowa": "IA",
    "Kansas": "KS",
    "Kentucky": "KY",
    "Louisiana": "LA",
    "Maine": "ME",
    "Maryland": "MD",
    "Massachusetts": "MA",
    "Michigan": "MI",
    "Minnesota": "MN",
    "Mississippi": "MS",
    "Missouri": "MO",
    "Montana": "MT",
    "Nebraska": "NE",
    "Nevada": "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Ohio": "OH",
    "Oklahoma": "OK",
    "Oregon": "OR",
    "Pennsylvania": "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    "Tennessee": "TN",
    "Texas": "TX",
    "Utah": "UT",
    "Vermont": "VT",
    "Virginia": "VA",
    "Washington": "WA",
    "West Virginia": "WV",
    "Wisconsin": "WI",
    "Wyoming": "WY",
    "District of Columbia": "DC",
    "American Samoa": "AS",
    "Guam": "GU",
    "Northern Mariana Islands": "MP",
    "Puerto Rico": "PR",
    "United States Minor Outlying Islands": "UM",
    "U.S. Virgin Islands": "VI",
}

export const taxAPI = async (country_code, state_name, zipcode) => {
  const username = "kyle@dataskeptic.com"
  const app_name = "work"
  const unique_id = "feaas-py.chalicelib.actions.vendor.printful.tax.GetTaxRate"
  const url = `https://4nbaxdraua.execute-api.us-east-1.amazonaws.com/api/api/${username}/${app_name}/${unique_id}`
  const state_code = us_state_to_abbrev[state_name]
  var tax_rate = 0.0;
  var shipping_taxable = true;
  var valid = true;

  if (state_code !== undefined) {
    const result = await axios({
      method: 'post',
      url: url,
      data: {country_code, state_code, zipcode}
    });
    const data = result.data
    try {
      tax_rate = data.outputs['tax_rate']['dval'];
      shipping_taxable = data.outputs['shipping_taxable']['bval'];
      valid = data.outputs['valid']['bval'];
    } catch (err) {
      tax_rate = 0.0;
      valid = false;
    }
  }
  // {"success": true, "outputs": {"tax_rate": {"ptype": "FLOAT", "dval": 0.095}, "shipping_taxable": {"ptype": "BOOLEAN", "bval": false}}, "primary_output": "tax_rate"}kpo
  return { tax_rate, shipping_taxable, valid }
}
