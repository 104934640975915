import './AboutUs.css';

import React from 'react';

function AboutUs() {
  return (
    <div className="AboutUs">
      <img className="office" src="https://s3.amazonaws.com/dataskeptic.com/imgs/2021/ds-office.jpg" />
      <div className="AboutUsInner">
        <h1>About Data Skeptic</h1>
        <p>Data Skeptic launched as a podcast in 2014.  Hundreds of interviews and tens of millions of downloads later, we're a widely recognized authoritative source on data science, artificial intelligence, machine learning, and similar topics.</p>
        <p>Data Skeptic runs in seasons.  We explore each season's theme by talking to active researchers and industry professionals contributing in some way to our theme.</p>
        <p>We hand pick our guests through an internal process.  We don't work with PR agencies and are not able to respond to the unsolicited submissions we get on a daily basis.  The best way to get on the show is to publish good research to the arxiv.  We crawl it.  We'll find you.</p>
        <p>Beyond the podcast, Data Skeptic is a boutique consulting firm.  Kyle is personally involved in all projects our team takes on.  Our work tends to focus in algorithmic design, cloud infrastructure, and end-to-end machine learning.</p>
      </div>
    </div>
  );
}

export default AboutUs;
