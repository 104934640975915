import './Sponsor.css';

function AnnualSponsor(props) {
  const sponsor = props.sponsor
  // <h3><a href={sponsor.url}>{sponsor.name}</a></h3>
  return (
    <div className="AnnualSponsor">
      <div className="AnnualSponsorLogoContainer"><img src={sponsor.logo} className="AnnualSponsorLogo" /></div>
      <a href={sponsor.url}>{sponsor.display_url}</a>
      <p>{sponsor.description}</p>
    </div>
  );
}

export default AnnualSponsor;