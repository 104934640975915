import React from 'react';
import { useParams } from 'react-router-dom';

function BlogTopic() {
  let { topic } = useParams();
  return (
    <div>
      <h1>{topic}</h1>
    </div>
  );
}

export default BlogTopic;
