import './Sponsors.css';
import Sponsor from './Sponsor';

function Sponsors(props) {
  const sponsors = props.sponsors;
  if (sponsors === undefined || sponsors.length === 0) {
    return <div></div>;
  }
  const divs = [];
  sponsors.sort((a,b) => a.rank - b.rank);
  for (const sponsor of sponsors) {
    divs.push(<Sponsor key={sponsor} sponsor={sponsor} />);
  }
  return (
    <div>
        <hr/>
        <p className="Thanks">Thanks to our sponsors for their support</p>
        {divs}
    </div>
  );
}

export default Sponsors;
