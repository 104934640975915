import React from 'react';
import { Helmet } from 'react-helmet';
// import getData from '../api/api'

function BlogRoot() {
  // let [responseData, setResponseData] = React.useState('')
  // getData()
  //     .then((response)=>{
  //         setResponseData(response.data)
  //         console.log(response)
  //     })
  //     .catch((error) => {
  //         console.log(error)
  //     })
  // resp.data = {}
  return (
    <div>
      <Helmet>
        <title>Data Skeptic: Blog</title>
      </Helmet>
      <h1>Blog</h1>
    </div>
  );
}

export default BlogRoot;
