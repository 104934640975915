import './BlogPage.css';
import ListenOn from '../listen/ListenOn';
import NotFound from '../general/NotFound';
import '../App.css';
import Guests from '../blog/Guests';
import Sponsors from '../blog/Sponsors';
import Avatar from '../blog/Avatar';
import Player from '../player/Player';
import RecommendNext from '../blog/RecommendNext';
import { Helmet } from 'react-helmet';
import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

const getBlogPageWithFetch = async (api, topic, year, slug) => {
  const url = `${api}/blog/${topic}/${year}/${slug}`;
  const response = await fetch(url);
  const jsonData = await response.json();
  return jsonData;
};

function BlogPage() {
  const default_image = 'https://s3.amazonaws.com/dataskeptic.com/ds_sm.jpg';
  let { topic, year, slug } = useParams();
  const [notFound, setNotFound] = useState(false);
  const [blogData, setBlogData] = useState(() => {
    getBlogPageWithFetch(process.env.REACT_APP_API_URL, topic, year, slug)
      .then(function (result) {
        setBlogData(result);
      })
      .catch(function (err) {
        console.log({url, topic, year, slug, err})
        setNotFound(true);
      });
    return {
      body: '',
      metadata: {
        title: 'Data Skeptic',
        enclosure_url: '',
        itunes_image: default_image,
      },
    };
  });
  if (notFound) {
    return (
      <div className="BlogPage">
        <NotFound />
      </div>
    );
  }
  var preview = <div></div>
  if (blogData['is_preview']) {
    preview = <div className="preview">This episode is not yet released.</div>
  }
  var body = blogData['body'];
  const m = blogData['metadata'];
  var guests = [];
  var sponsors = blogData.sponsors;
  var title = slug + ' | ' + topic;
  if (blogData && blogData['guests']) {
    guests = blogData['guests'];
  }
  if (m && m['title']) {
    title = m['title'];
  }
  var release_date = '...';
  if (m && m['release_date']) {
    release_date = m['release_date'];
  }
  var enclosure_url = undefined;
  if (m && m['enclosure_url']) {
    enclosure_url = m['enclosure_url'];
  }
  var cover_image = default_image;
  if (m && m['image_url']) {
    cover_image = m['image_url'];
  }
  if (m && m['itunes_image'] && m['itunes_image'] !== default_image) {
    cover_image = m['itunes_image'];
  }
  var seasons = [];
  if (m && m['seasons']) {
    seasons = m['seasons'];
  }
  var desc = '';
  if (m && m['itunes_subtitle']) {
    desc = m['itunes_subtitle'];
  }
  if (body === '') {
    return <div>Loading</div>
  }
  var tbody = ""
  if (blogData && blogData['transcript_rendered']) {
    tbody = blogData['transcript_rendered'];
  }
  var player;
  var listen;
  if (enclosure_url && enclosure_url !== '') {
    player = <Player url={enclosure_url} artwork={cover_image} title={title} />;
    listen = <div><br/><ListenOn /></div>;
  } else if (release_date && release_date !== '...' && topic === 'episodes') {
    player = (
      <div className="preview">
        This episode will be released on <b>{release_date}</b>.
      </div>
    );
    listen = <div><br/><ListenOn /></div>;
  } else {
    player = <div></div>;
    listen = <div></div>;
  }
  console.log({blogData})
  //const guid = m['guid'];
  // const itunes_duration = m['itunes_duration'];
  //const public_url = m['public_url'];
  //const timestamp = m['timestamp'];
  const url = `https://dataskeptic.com/blog/${topic}/${year}/${slug}`;
  if (topic != "episodes") {
    return (
      <div className="BlogPage">
        <Helmet>
          <title>{title}</title>
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={desc} />
          <meta name="twitter:image" content={cover_image} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={desc} />
          <meta property="og:image" content={cover_image} />
          <meta property="og:url" content={url} />
        </Helmet>
        <div dangerouslySetInnerHTML={{ __html: body }} />
        <Avatar timestamp={blogData.timestamp} author={blogData?.author} />
        <div data-ea-publisher="dataskepticcom" data-ea-type="image"></div>
      </div>
    )
  }
  return (
    <div className="BlogPage">
      <Helmet>
        <title>{title}</title>
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={cover_image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={cover_image} />
        <meta property="og:url" content={url} />
      </Helmet>
      <Container>
        {preview}
        <Row>
          <Col xs="12" sm="4">
            <div className="Left">
              <img src={cover_image} className="album_art" alt={title} />
              <div data-ea-publisher="dataskepticcom" data-ea-type="image"></div>
            </div>
          </Col>
          <Col xs="12" sm="8">
            {listen}
            <br />
            {player}
            <br />
            <div dangerouslySetInnerHTML={{ __html: body }} />
            <Guests guests={guests} />
            <Sponsors sponsors={sponsors} />
            <hr />
            <div className="FindOn">
              <a href="https://podcasts.apple.com/us/podcast/data-skeptic/id890348705">
                <img
                  className="ListenOnImg"
                  src="https://s3.amazonaws.com/dataskeptic.com/images/blogpage/apple-podcasts.png"
                />
              </a>
              <a href="https://open.spotify.com/show/1BZN7H3ikovSejhwQTzNm4">
                <img
                  className="ListenOnImg"
                  src="https://s3.amazonaws.com/dataskeptic.com/images/blogpage/spotify.png"
                />
              </a>
              <br/><br/>
              <div className="transcription" dangerouslySetInnerHTML={{ __html: tbody }} />
            </div>
          </Col>
        </Row>
      </Container>
      <RecommendNext seasons={seasons} />
    </div>
  );
}

export default BlogPage;
