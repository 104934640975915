import { createSlice } from '@reduxjs/toolkit';
import toNumber from 'lodash/toNumber';
import isUndefined from 'lodash/isUndefined';

const initialState = {
  items: [],
  listProducts: [],
  discount: 0.0,
  subtotal: 0.0,
  shipping: 0.0,
  tax: 0.0,
  taxRate: 0.0,
  total: 0.0,
  refreshTax: false,
  email: undefined,
  shippingTaxable: false,
  shipping_name: undefined,
  shipping_address: undefined,
  shipping_city: undefined,
  shipping_postal_code: undefined,
  shipping_country: undefined,
  shipping_region: undefined
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateItemToCart: (state, { payload = {} }) => {
      const { product_id = undefined, quantity = 1, selected_size = '-' } = payload;
      if (product_id) {
          const item = state.items.find(
            (item) => item.product_id === product_id && item.selected_size === selected_size,
          );
          if (item) {
            let currentSubTotal = 0;
            let currentShipping = 0;
            let itemIndex = undefined;
            state.items.map((item, index) => {
              if (item.product_id === product_id && item.selected_size === selected_size) {
                item.quantity += quantity;
              }
              if (item.quantity > 0) {
                currentSubTotal += item.quantity * item.unit_price;
                currentShipping += item.shipping;
              }

              if (item.quantity == 0) itemIndex = index;
              return item;
            });

            if (!isUndefined(itemIndex)) state.items.splice(itemIndex, 1);

            state.subtotal = currentSubTotal;
            var tax = state.subtotal * state.taxRate
            state.shipping = currentShipping;
            if (state.shippingTaxable) {
              tax += state.shipping * state.taxRate
            }
            state.total = parseInt((currentSubTotal + currentShipping + tax) * 100) / 100;
            state.tax = tax
          } else {
            const product = state.listProducts.find(
              (p) => p.product_id === product_id,
            );
            if (product) {
              const nitem = {
                product_id,
                name: product.name,
                img_url: product.img_url,
                unit_price: product.unit_price,
                shipping: product.shipping,
                quantity: 1,
                selected_size: selected_size
              }
              state.items.push(nitem);
            }
            state.subtotal += product.unit_price;
            state.shipping += product.shipping;
            state.total += product.unit_price + product.shipping;
            var taxable = state.subtotal
            if (state.shippingTaxable) {
              taxable += state.shipping * state.taxRate
            }
            state.tax = taxable * state.taxRate
        }
      }
    },
    setListProducts: (state, { payload = [] }) => {
      if (state.items === undefined) {
        state.items = []
      }
      state.listProducts = payload.map((item) => {
        const nitem = {
          ...item,
          shipping: toNumber(item.shipping),
          unit_price: toNumber(item.unit_price),
        }
        if (item.sizes) {
          nitem['selected_size'] = item.sizes[0]
        } else {
          nitem['selected_size'] = '-'
        }
        return nitem
      });
    },
    updateShipping: (state, { payload = {} }) => {
      var refreshTax = false;
      if (payload.shipping_postal_code && payload.shipping_postal_code !== state.shipping_postal_code) {
        refreshTax = true;
      }
      if (payload.shipping_region && payload.shipping_region !== state.shipping_region) {
        refreshTax = true;
      }
      if (payload.shipping_country && payload.shipping_country !== state.shipping_country) {
        refreshTax = true;
      }
      const nstate = { ...state, ...payload }
      nstate.refreshTax = refreshTax
      return nstate;
    },
    updateTax: (state, { payload = {} }) => {
      const { tax_rate, shipping_taxable, valid } = payload;
      const taxRate = tax_rate;
      var tot = state.subtotal;
      if (shipping_taxable) {
        tot += state.shipping;
      }
      const tax = parseInt(taxRate * tot * 100) / 100;
      const total = parseInt((state.subtotal + state.shipping + tax) * 100) / 100;
      return { ...state, taxRate, tax, total, refreshTax: false, shippingTaxable: shipping_taxable }
    },
    resetCart: () => {
      return initialState;
    },
    updateSize: (state, { payload = {} }) => {
      const { object_id, selection } = payload;
      const index = state.listProducts.findIndex(
        (product) => product.object_id === object_id,
      );
      if (index !== -1 && state.listProducts?.[index]?.selected_size)
        state.listProducts[index].selected_size = selection;
    },
    removeItemToCart: (state, { payload = {} }) => {
      const { product_id = undefined, selected_size, quantity = 1 } = payload;
      const index = state.items.findIndex(
        (item) => item.product_id === product_id && item.selected_size == selected_size,
      );
      if (index !== -1) state.items.splice(index, 1);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateItemToCart,
  setListProducts,
  updateShipping,
  updateTax,
  resetCart,
  updateSize,
  removeItemToCart,
} = cartSlice.actions;

export default cartSlice.reducer;
