import React from 'react';
import { Container, Row } from 'reactstrap';

const ThankYou = () => {
  return (
    <div className="Store">
      <Container>
        <Row>
          <div className="text-center">
            <h1 className="display-3 text-center">Thank You!</h1>
            <br />
            <div className="lead">
              <div>
                <strong>Thank you for purchasing</strong>
              </div>
              <div>Your payment was successful</div>
            </div>
            <hr />
            <p>
              Having trouble?{' '}
              <a href="mailto:support@dataskeptic.com">Contact us</a>
            </p>
            <p className="lead">
              <a className="btn btn-primary btn-sm" href="/" role="button">
                Continue to homepage
              </a>
            </p>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ThankYou;
