import './Product.css';
import SizeSelector from './SizeSelector';
import { Container, Row, Col, Button, ButtonGroup } from 'reactstrap';
import { useDispatch } from 'react-redux';
// import actions
import { updateItemToCart } from 'redux/cart';

function Product(props) {
  const dispatch = useDispatch();
  const product = props.product;
  const quantity = props.quantity;
  var qdiv = <span></span>;
  if (quantity > 0) {
    qdiv = <span> {quantity} in cart</span>;
  }
  const sizes = product.sizes ? product.sizes : undefined;
  const selection = product.selected_size
  const image2_url = product['image2_url']
  return (
    <div className="Product">
      <Container>
        <Row>
          <Col xs="12" sm="4">
            <img className="product-image" src={product.img_url} />
            <img className="product-image" src={image2_url} />
          </Col>
          <Col xs="12" sm="8">
            <h1 className="product-name">{product.name}</h1>
            <p>{product.desc}</p>
            <div className="product-price">
              <span className="UnitPrice">${product.unit_price}</span> (+ $
              {product.shipping} shipping)
            </div>
            <div>
              <SizeSelector sizes={sizes} selection={selection} object_id={product.object_id} />
              <ButtonGroup>
                <Button
                  outline
                  color="warning"
                  onClick={() =>
                    dispatch(
                      updateItemToCart({
                        product_id: product.product_id,
                        selected_size: product.selected_size,
                        quantity: -1,
                      }),
                    )
                  }
                >
                  -
                </Button>
                <Button
                  outline
                  color="danger"
                  onClick={() =>
                    dispatch(
                      updateItemToCart({
                        product_id: product.product_id,
                        selected_size: product.selected_size,
                        quantity: 1,
                      }),
                    )
                  }
                >
                  +
                </Button>
              </ButtonGroup>
              <span className="cart-item-quantity">{qdiv}</span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Product;
