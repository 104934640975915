import axios from './index';

export const getSeasonsAPI = async () => {
  try {
    const result = await axios({
      method: 'get',
      url: '/episodes',
    });
    return result.data.seasons;
  } catch (ex) {
    console.log(ex);
    return [];
  }
};
