import './NavBar.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
// import APIs
import { getSeasonsAPI } from 'api/episodes';

function NavBar(props) {
  const isActive = false;
  const [q, setQ] = useState('');
  const [lastQ, setLastQ] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [listSeasons, setListSeasons] = useState([]);

  useEffect(() => {
    const getSeasons = async () => {
      const data = await getSeasonsAPI();
      setListSeasons(data);
    };

    getSeasons();
  }, []);

  const submitValue = () => {
    // setWaiting(true)
    window.location = `/search?q=${q}`;
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      //Write you validation logic here
    }
  };
  // <li className={isActive ? 'aactive' : 'mli'}>
  //     <Link className="nav-item" to="/">Home</Link>
  // </li>
  return (
    <Navbar collapseOnSelect expand="lg" variant="dark">
      <Container>
        <Navbar.Brand href="/">
          <div className="nav-logo-container">
            <img src="/nav-logo.svg" alt="Data Skeptic logo" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" />
          <Nav>
            <NavDropdown title="Seasons" id="collasible-nav-dropdown">
              {listSeasons.map((season) => (
                <NavDropdown.Item key={season.slug}>
                  <Link className="nav-item" to={`/episodes/${season.slug}`}>
                    {season.title}
                  </Link>
                </NavDropdown.Item>
              ))}
              <NavDropdown.Divider />
              <NavDropdown.Item>
                <Link className="nav-item" to={`/episodes`}>
                  See All Seasons
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link>
              <Link className="nav-item" to="/store">
                Store
              </Link>
            </Nav.Link>

            <NavDropdown title="Sponsors" id="collasible-nav-dropdown">
              <NavDropdown.Item>
                <Link className="nav-item" to="/advertising">
                  Advertise with Us
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link className="nav-item" to="/sponsors">
                  See All Sponsors
                </Link>
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="About" id="collasible-nav-dropdown">
              <NavDropdown.Item>
                <Link className="nav-item" to="/consulting">Consulting</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link className="nav-item" to="/about/dataskeptic">
                  About Data Skeptic
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link className="nav-item" to="/about/kyle-polich">
                  About Kyle
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link>
              <Link className="nav-item" to="/members">
                Login
              </Link>
            </Nav.Link>
            <Nav.Link>
              <input
                className="search-box"
                placeholder="Search..."
                name="search"
                onChange={(e) => setQ(e.target.value)}
                onBlur={submitValue}
                onKeyDown={(e) => handleKeyPress(e)}
              />
              <button onClick={submitValue} className="search-btn">
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
