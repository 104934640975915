import React from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

function Avatar(props) {
  const timestamp = props.timestamp
  const author = props.author
  let { topic } = useParams();
  if (author === undefined) {
    return <div></div>
  }
  return (
      <div class="avatar">
        <div class="avatar-left">
          <img
            className="avatar-image"
            src={author.image_url}
            alt={author?.name}
          />
        </div>
        <div class="avatar-right">
          <div key={author?.slug} class="avatar-name">
            {author?.name}
          </div>
          <div>
          {timestamp && (
            <div className="time">
              {dayjs(timestamp).format('MM/DD/YYYY')}
            </div>
          )}
          </div>
        </div>
      </div>
  );
}

export default Avatar;
