import './Sponsors.css';
import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import AnnualSponsor from './AnnualSponsor';
import SilverSponsor from './SilverSponsor';


function Sponsors(props) {
  const getDataFromApi = async () => {
    var now = new Date();
    var start = new Date(now.getFullYear(), 0, 0);
    var diff = now - start;
    var oneDay = 1000 * 60 * 60 * 24;
    var day = Math.floor(diff / oneDay);
    console.log('Day of year: ' + day);    
    const response = await fetch(`${process.env.REACT_APP_API_URL}/sponsors`);
    const r = await response.json();
    const rsponsors = r['sponsors'];
    var annual_count = 0
    for (const sponsor of rsponsors) {
      sponsor['rank'] = parseInt(sponsor['rank'])
      if (sponsor['rank'] == 1) {
        annual_count += 1
      }
    }
    var c = day % annual_count;
    for (const sponsor of rsponsors) {
      sponsor['r2'] = sponsor['rank'] * 10 + c
      if (sponsor['rank'] == 1) {
        console.log({rank: sponsor['rank'], r2: sponsor['r2'], n: sponsor['name']})
        c += 1
        c = c % annual_count;
      }
    }

    rsponsors.sort((a,b) => 100 * (a.r2 - b.r2));
    setSponsors(rsponsors);
    setLoading(false);
  };
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getDataFromApi();
  }, []);
  const year = (new Date()).getYear() + 1900
  return (
    <div className="Sponsors">
      <div className="SponsorTopContainer">
        <h1 className="SponsorsTitle">{year} Sponsors</h1>
        <p>Data Skeptic has brought you <b>weekly</b> interviews with topics in and around data science since 2014.  These efforts are only possible thanks to our sponsors.  Please take a moment and consider our <b>{year}</b> Data Skeptic annual and silver sponsors listed below by checking out these generous supporters!</p>
      </div>
      {loading && <h3><center>Loading...</center></h3>}
      <Container>
        <Row>
          {sponsors.map(function(sponsor, idx) {
              if (sponsor.sponsor_type == "annual") {
                return <Col xs="12" sm="12"><AnnualSponsor sponsor={sponsor} /></Col>
              } else {
                return <Col xs="12" sm="6"><SilverSponsor sponsor={sponsor} /></Col>
              }
           })}
        </Row>
      </Container>
    </div>
  );
}

export default Sponsors;