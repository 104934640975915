import './SeasonItem.css';
import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactHowler from 'react-howler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

function SeasonItem(props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const blog = props.blog;
  const title = blog.title;
  var body = blog.body;
  if (body === undefined) {
    body = blog.content_encoded;
  }
  const dt = blog.dt;
  var public_url = blog.public_url;
  if (public_url === undefined) {
    public_url = blog.link;
  }
  if (public_url === undefined) {
    public_url = 'https://dataskeptic.com/blog/';
  }
  var enclosure_url = blog.enclosure_url;
  var itunes_duration = blog.itunes_duration;
  const i = public_url.indexOf('.com/');
  const abspath = public_url.substring(i + 4, public_url.length);
  var image = undefined
  if (blog.thumbnail_url !== undefined) {
    image = blog.thumbnail_url;
  } else if (blog.itunes_image !== undefined) {
    image = blog.itunes_image;
  } else {
    image = '/logo.jpg';
  }
  var buttonText = 'Play ';
  if (isPlaying) {
    buttonText = 'Pause ';
  }
  var icon = faPlay;
  if (isPlaying) {
    icon = faPause;
  }
  const playerDuration = itunes_duration;
  if (body === undefined) {
    return (
      <div className="SeasonItem"></div>
    )    
  }
  const ii = body.indexOf("<p>");
  if (ii != -1) {
    const j = body.indexOf('<p>', ii + 1);
    if (j != -1) {
      body = body.substring(0, j);
    }
  }
  /*
            <div className="seasonPlayButton">
              <ReactHowler src={enclosure_url} playing={isPlaying} />
              <div
                className="seasonPlayButton"
                onClick={() => setIsPlaying(!isPlaying)}
              >
                <FontAwesomeIcon icon={icon} /> {buttonText}{' '}
                <span className="playerDuration">{playerDuration}</span>
              </div>
            </div>
  */
  return (
    <div className="SeasonItem">
      <Container>
        <Row>
          <Col xs="12" sm="4">
            <img className="album-cover" src={image} alt={title} />
          </Col>
          <Col xs="12" sm="8">
            <Link className="title" to={abspath}>
              {title}
            </Link>
            <div className="season-item-date">{dt}</div>
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SeasonItem;
