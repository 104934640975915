import './Sponsor.css';
import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';


function Sponsor(props) {
  let { sponsor } = useParams();
  const [waiting, setWaiting] = useState(false);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [displayUrl, setDisplayUrl] = useState("");
  const [description, setDescription] = useState("");
  const [logo, setLogo] = useState("");
  const [episodes, setEpisodes] = useState([]);
  const [error, setError] = useState(undefined);

  const submitValue = () => {
      setWaiting(true)
  }

  const getBlogPageWithFetch = async (api, topic, year, slug) => {
    const url = `${api}/blog/${topic}/${year}/${slug}`;
    const response = await fetch(url);
    const jsonData = await response.json();
    return jsonData;
  };

  const getDataFromApi = async () => {
      const api_url = process.env.REACT_APP_API_URL
      const url = `${api_url}/sponsors/${sponsor}`
      console.log({url})
      const response = await fetch(url);
      const r = await response.json();
      if (r.error !== undefined) {
        setError(r.error)
      } else {
        setName(r.name);
        setUrl(r.url);
        setDisplayUrl(r.display_url);
        setDescription(r.description);
        setLogo(r.logo);
        var episodes = r.episodes || []
        for (const path of episodes) {
          const arr = path.split('/')
          const episode = await getBlogPageWithFetch(api_url, arr[2], arr[3], arr[4])
          episode['path'] = path
          episodes.push(episode)
        }
        setEpisodes(episodes);        
      }
  }

  useEffect(() => {
      getDataFromApi()
  }, []);

  if (error) {
    return (
      <div>
        <h2>Not found</h2>
        <p>We could not find that sponsor</p>
      </div>
    )
  }

  return (
    <div className="Sponsor">
      <div className="AnnualSponsor">
        <Container>
          <Row>
            <Col xs="12" sm="12">
              <div className="AnnualSponsorLogoContainer"><img src={logo} className="AnnualSponsorLogo" /></div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12">
              <p><a href={url}>{displayUrl}</a></p>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12">
              <p>{description}</p>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12">
              {episodes.map(function(episode){
                return (
                  <div key={episode.path}>
                    <p><a href={episode.path}>{episode.title}</a></p>
                  </div>
                )
              })}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Sponsor;