import Guest from './Guest';

function Guests(props) {
  const guests = props.guests;
  if (guests.length === 0) {
    return <div></div>;
  }
  const divs = [];
  for (const guest of guests) {
    divs.push(<Guest key={guest} guest={guest} />);
  }
  return (
    <div>
      {divs}
    </div>
  );
}

export default Guests;
