import { Container, Row, Col } from 'reactstrap';
import './index.css';

import React from 'react';

function AboutKyle() {
  return (
    <div className="AboutKyle">
      <Container>
        <Row>
          <Col xs="12" sm="4">
            <img className="kyleimg" src="https://s3.amazonaws.com/dataskeptic.com/imgs/2020/kyle.png" alt="Kyle Polich" />
          </Col>
          <Col xs="12" sm="8">
            <h1>About Kyle Polich</h1>
            <p>Kyle is the founder of Data Skeptic, a popular podcast about artificial intelligence, machine learning, and data science.  Outside of hosting the show, he runs a boutique consulting group that helps small and medium enterprise companies deploy data driven automated solutions in the cloud.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutKyle;
