import { useDispatch } from 'react-redux';
import { Input } from 'reactstrap';
import { updateSize } from 'redux/cart';

function SizeSelector(props) {
  const dispatch = useDispatch();
  const sizes = props.sizes
  const selection = props.selection
  const object_id = props.object_id
  if (sizes === undefined || sizes.length == 0) {
    return (
      <div className="SizeSelector">
      </div>
    );
  }
  return (
    <div key={props.key} className="SizeSelector">
      <Input 
        type="select"
        value={selection}
        onChange={e => dispatch(updateSize({ object_id, selection: e.target.value }))}>
        {sizes.map(o => (
          <option key={o} value={o}>{o}</option>
        ))}
      </Input>
    </div>
  );
}

export default SizeSelector;
