import Player from '../player/Player';
import './LatestPodcast.css';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

function LatestPodcast(props) {
  const title = props.episode.title;
  // const duration = props.episode.itunes_duration
  const guests = props.episode.guests;
  const ts = props.episode.timestamp;
  var dt = '';
  if (ts !== undefined) {
    dt = new Date(ts).toISOString().slice(0, 10);
  }
  const content_encoded = props.episode.content_encoded;
  const enclosure_url = props.episode.enclosure_url;
  var public_url = props.episode.public_url;
  if (public_url === undefined) {
    public_url = props.episode.link;
  }
  if (public_url === undefined) {
    public_url = 'https://dataskeptic.com/blog/';
  }
  const i = public_url.indexOf('.com/');
  const abspath = public_url.substring(i + 4, public_url.length);
  var image = props.episode.itunes_image;
  if (image === undefined) {
    image = 'https://s3.amazonaws.com/dataskeptic.com/ds_sm.jpg';
  }
  return (
    <div className="LatestPodcast">
      <div className="tight">
        <p className="latest-podcast-date">
          <b>From the archive:</b> {dt}
        </p>
        <Link className="myh2" to={abspath}>
          <h2 className="myh2">{title}</h2>
        </Link>
        <Container>
          <Row>
            <Col xs="4">
              <Link to={abspath}>
                <img className="latest-podcast-album" alt={title} src={image} />
              </Link>
            </Col>
            <Col xs="8">
              <div
                className="abstract"
                dangerouslySetInnerHTML={{ __html: content_encoded }}
              />
            </Col>
          </Row>
        </Container>
        <br />
        <Player title={title} url={enclosure_url} guests={guests} mode="mini" />
      </div>
    </div>
  );
}

export default LatestPodcast;
