import './Season.css';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';
import SeasonItem from './SeasonItem';
import { useParams } from 'react-router-dom';

const getWithFetch = async (season) => {
  const url = `${process.env.REACT_APP_API_URL}/episodes/${season}`;
  const response = await fetch(url);
  const jsonData = await response.json();
  return jsonData;
};

function Season() {
  let { season } = useParams();
  const [episodeData, setEpisodeData] = useState(() => {
    getWithFetch(season).then(function (result) {
      setEpisodeData(result);
    });
    return {
      items: [],
      title: 'Loading...',
      abstract: '',
      itunes_image: 'https://s3.amazonaws.com/dataskeptic.com/ds_sm.jpg',
    };
  });
  var title = episodeData.title;
  if (title === undefined) {
    title = 'Data Skeptic: ' + season;
  }
  const cols = [];
  var c = 0;
  for (const episode of episodeData.items) {
    const iid = `iid${c}`;
    const col = (
      <Col key={iid} xs="12" sm="6">
        <div className="season-blog-item">
          <SeasonItem blog={episode} />
        </div>
      </Col>
    );
    cols.push(col);
    c += 1;
  }
  const body = episodeData.abstract;
  const itunes_image = episodeData.itunes_image;
  return (
    <div className="Season">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <Row>
          <Col xs="12" sm="4">
            <img className="home-image" src={itunes_image} alt={title} />
          </Col>
          <Col xs="12" sm="8">
            <div className="podcast-small">
              <h1>{title}</h1>
              <p>{body}</p>
            </div>
          </Col>
        </Row>
      </Container>
      <div data-ea-publisher="dataskepticcom" data-ea-type="text"></div>
      <Container>
        <Row>{cols}</Row>
      </Container>
    </div>
  );
}

export default Season;
