import './Consulting.css';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';

function Consulting() {
/*
      <h2>Technologies</h2>
      <p>The problem defines the right tools, but some of the solutions we frequently support are...</p>
      <ul>
        <li>AWS and GCP</li>
        <li>Docker</li>
        <li>Terraform</li>
        <li>Python, Javascript, R, Rust, Go, Java</li>
        <li>sklearn</li>
        <li>tensorflow</li>
        <li>Spark</li>
        <li>React.js</li>
      </ul>
*/
  return (
    <div className="Consulting">
      <h1>Data Skeptic Consulting</h1>
      <p>Although best known for our podcast, Data Skeptic's "day job" is helping small and medium enterprise organizations design, develop, and deploy cloud based, data-driven solutions.</p>
      <h3>Bringing Research to Production</h3>
      <p>Our focus as a team is to help organizations identify, synthesize, and leverage the best available algorithms, methodologies, and techniques from classical approaches to insights found in pre-print research.</p>
      <p>In collaboration with our client <a href="https://atlasground.com/">Atlas Space Operations</a> we documented one of our projects in the paper <a href="https://ieeexplore.ieee.org/document/9527291">An Optimization Framework for Resource Allocation in Multi-Tenant Communication Networks</a> which appeared in the IEEE Cognitive Communications for Aerospace Applications Workshop (CCAAW)</p>
      <p>For an example of our software deliverables, check out our stream processing solutions (currently in beta) at <a href="https://beta.dataskeptic.com">beta.dataskeptic.com</a>.</p>
      <h2>How we can help</h2>
      <ul>
        <li>Rapid prototyping</li>
        <li>Infrastructure assessment</li>
        <li>Cloud development</li>
        <li>Long term project planning</li>
        <li>End-to-end machine learning systems</li>
        <li>Team training and hiring</li>
      </ul>
      <br/>
      <Container>
        <Row>
          <Col xs="12" sm="4">
            <img className="kyleimg" src="https://s3.amazonaws.com/dataskeptic.com/imgs/2020/kyle.png" alt="Kyle Polich" />
            <br/>
            <i>Kyle Polich, Founder</i>
          </Col>
          <Col xs="12" sm="8">
            <h1>Let's start a Conversation</h1>
            <p>Use the link below to book me for a no-obligation conversation about how we might be able to help solve your current challenge or take the next step forward.</p>
            <br/>
            <a className="booklink" href="https://calendly.com/polich/consultation">Book me</a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Consulting;
