import './BlogTopicYear.css';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

function BlogTopicYear() {
  let { topic, year } = useParams();
  const [episodesData, setEpisodesData] = useState({"episodes": []});
  useEffect(() => {
    getDataFromApi();
  }, []);
  const getDataFromApi = async () => {
    const response2 = await fetch(`${process.env.REACT_APP_API_URL}/blog/episodes/${year}`);
    const bresp = await response2.json();
    setEpisodesData(bresp);
  };
  const lst = episodesData.episodes.map(function(episode){
    const dt = new Date(episode.timestamp)
    const href = episode['link']
    return (
      <div className="BTY" key={episode.timestamp}>
        <p className="BTY">
          <span className="year-date">{dt.getYear()+1900}-{(dt.getMonth()+1).toString().padStart(2, "0")}-{(dt.getDate()+1).toString().padStart(2, "0")}</span>
          &nbsp;-&nbsp;
          <b><a className="year-link" href={href}>{episode.title}</a></b>
        </p>
      </div>
    );
  })
  return (
    <div className="BlogTopicYear">
      <Helmet>
        <title>Data Skeptic: Blog</title>
      </Helmet>
      <h1>
        {topic} ({year})
      </h1>
      {lst}
    </div>
  );
}

export default BlogTopicYear;
