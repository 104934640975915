import './Footer.css';
import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

function Footer() {
  const [q, setQ] = useState('');
  // <!--
  // <p className="footer-link-p"><Link className="footer-link" to="/about-us">About</Link></p>
  // -->
  const submitValue = () => {
      // setWaiting(true)
      window.location = `/search?q=${q}`
  }

  const handleKeyPress = (e) => {
     if(e.keyCode === 13){
       e.target.blur(); 
       //Write you validation logic here
     }
  }
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col xs="12" sm="6" md="3">
            <img src="/nav-logo.svg" alt="data skeptic logo" />
            <p className="footer-p">
              Your trusted podcast, centered on data science, machine learning,
              and artificial intelligence.
            </p>
          </Col>
          <div className="FooterSearch">
            <Col xs="12" sm="6" md="9">
              <input className="search-box" placeholder="Search..." name="search" onChange={e => setQ(e.target.value)} onBlur={submitValue} onKeyDown={(e) => handleKeyPress(e)} />
              <button onClick={submitValue} className="search-btn"><FontAwesomeIcon icon={faSearch} /></button>
            </Col>
          </div>
          <Col xs="12" sm="2">
            <div className="footerDesktopOnly">
              <div className="footer-link-header">Content</div>
              <p className="footer-link-p">
                <Link className="footer-link" to="/episodes/time-series">
                  Time Series
                </Link>
              </p>
              <p className="footer-link-p">
                <Link className="footer-link" to="/episodes/consensus">
                  Consensus
                </Link>
              </p>
              <p className="footer-link-p">
                <Link className="footer-link" to="/episodes/interpretability">
                  Interpretability
                </Link>
              </p>
              <p className="footer-link-p">
                <Link className="footer-link" to="/episodes/fake-news">
                  Fake News
                </Link>
              </p>
              <p className="footer-link-p">
                <Link className="footer-link" to="/episodes/nlp">
                  NLP
                </Link>
              </p>
              <p className="footer-link-p">
                <Link
                  className="footer-link"
                  to="/episodes/artificial-intelligence"
                >
                  AI
                </Link>
              </p>
            </div>
          </Col>
          <Col xs="12" sm="2">
            <div className="footerDesktopOnly">
            <div className="footer-link-header">Data Skeptic</div>
            <p className="footer-link-p">
              <Link className="footer-link" to="/contact-us">
                Contact
              </Link>
            </p>
            <p className="footer-link-p">
              <Link className="footer-link" to="/advertising">
                Advertising
              </Link>
            </p>
            <p className="footer-link-p">
              <Link className="footer-link" to="/privacy-policy">
                Privacy Policy
              </Link>
            </p>
            </div>
          </Col>
          <Col xs="12" sm="2">
            <div className="footerDesktopOnly">
            <div className="footer-link-header">Connect</div>
            <p className="footer-link-p">
              <a className="footer-link" href="http://twitter.com/dataskeptic">
                Twitter
              </a>
            </p>
            <p className="footer-link-p">
              <a
                className="footer-link"
                href="https://www.youtube.com/dataskeptic"
              >
                Youtube
              </a>
            </p>
            <p className="footer-link-p">
              <a
                className="footer-link"
                href="https://www.facebook.com/dataskeptic/"
              >
                Facebook
              </a>
            </p>
            </div>
          </Col>
          <Col xs="12" sm="2">
            <div className="footerDesktopOnly">
            <div className="footer-link-header">Subscribe</div>
            <p className="footer-link-p">
              <a
                className="footer-link"
                href="https://podcasts.apple.com/us/podcast/data-skeptic/id890348705"
              >
                Apple Podcasts
              </a>
            </p>
            <p className="footer-link-p">
              <a
                className="footer-link"
                href="https://podcasts.google.com/feed/aHR0cHM6Ly9kYXRhc2tlcHRpYy5saWJzeW4uY29tL3Jzcw=="
              >
                Google Play
              </a>
            </p>
            <p className="footer-link-p">
              <a
                className="footer-link"
                href="https://open.spotify.com/show/1BZN7H3ikovSejhwQTzNm4"
              >
                Spotify
              </a>
            </p>
            <p className="footer-link-p">
              <Link className="footer-link" to="/feed.rss">
                RSS Feed
              </Link>
            </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
