import React from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const ShippingDetail = () => {
  const cart = useSelector((state) => state.cart);
  const history = useHistory();

  const backToStore = () => {
    history.replace('/store');
  };
  return (
    <div class="card border-0">
      <div class="pb-0">
        <p class="card-text text-muted mt-4 space">SHIPPING DETAILS</p>
        <hr class="my-0" />
      </div>
      <div class="card-body">
        <div class="">
          <div class="col-auto mt-0">
            <p>
              <b>{cart.shipping_name}</b>
            </p>
          </div>
          <div class="col-auto">
            <p>
              <b>{cart.email}</b>
            </p>
          </div>
          <div class="col-auto">
            <p><b>{cart.shipping_address}</b></p>
            <p><b>{cart.shipping_city}, {cart.shipping_region} {cart.shipping_postal_code}</b></p>
            <p><b>{cart.shipping_country}</b></p>
          </div>
          <div class="col-auto">
            <Button color="info" outline onClick={backToStore}>
              Edit Shipping
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingDetail;
