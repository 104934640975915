import './Search.css';
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import SearchResult from './SearchResult'


function Search(props) {
  const [q, setQ] = useState(props.q);
  const [lastQ, setLastQ] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [results, setResults] = useState([]);

  const submitValue = () => {
      setWaiting(true)
  }

  const getDataFromApi = async () => {
      const api_url = 'https://por8ht9sv9.execute-api.us-east-1.amazonaws.com/api'
      const url = `${api_url}/blog/search?q=${q}`
      const response = await fetch(url);
      const r = await response.json();
      const results = r['results']
      setWaiting(false)
      setResults(results)
  }

  useEffect(() => {
      if (q !== "" && q !== lastQ) {
          setLastQ(q)
          getDataFromApi()
      }
  }, [waiting]);

  const handleKeyPress = (e) => {
     if(e.keyCode === 13){
       e.target.blur(); 
       //Write you validation logic here
     }
  }
  var noResultsMessage = <div></div>
  if (!waiting && results.length===0) {
    noResultsMessage = <div>No results found</div>
  }
  const searchResults = results.map((d) => <SearchResult key={d.url} url={d.url} title={d.title} description={d.description} />);

  return (
    <div className="Search">
    <input className="search-box-main" placeholder="Search..." name="search" value={q} onChange={e => setQ(e.target.value)} onBlur={submitValue} onKeyDown={(e) => handleKeyPress(e)} />
    <button onClick={submitValue} className="search-btn"><FontAwesomeIcon icon={faSearch} /></button>
    <br/><br/>
    {searchResults}
    {noResultsMessage}
    </div>
  );
}

export default Search;