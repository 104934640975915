import './BlogItem.css';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Avatar from '../blog/Avatar';
import dayjs from 'dayjs';

function BlogItem(props) {
  const blog = props.blog;
  const title = blog.title;
  var body = blog.body;
  if (body === undefined) {
    body = blog.content_encoded;
  }
  const dt = blog.dt;
  var public_url = blog.public_url;
  if (public_url === undefined) {
    public_url = blog.link;
  }
  if (public_url === undefined) {
    public_url = 'https://dataskeptic.com/blog/';
  }
  const i = public_url.indexOf('.com/');
  const abspath = public_url.substring(i + 4, public_url.length);
  var image = blog.itunes_image;
  if (image === undefined) {
    image = '/spinner.gif';
  }
  var avatar = <div></div>
  if (blog.author) {
    avatar = <Avatar timestamp={blog.timestamp} author={blog.author} />
  }
  return (
    <div className="BlogItem">
      <Container>
        <Row>
          <Col xs="12" sm="4">
            <img className="album-cover" src={image} alt={title} />
          </Col>
          <Col xs="12" sm="8">
            <Link className="title" to={abspath}>
              {title}
            </Link>
            <div className="blog-item-date">{dt}</div>
            <div dangerouslySetInnerHTML={{ __html: body }} />
            {avatar}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BlogItem;
