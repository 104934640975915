import './SearchResult.css';

function SearchResult(props) {
    return (
      <div className="SearchResult">
        <h2><a href={props.url}>{props.title}</a></h2>
        <div dangerouslySetInnerHTML={{ __html: props.description }} />
      </div>
  );
}

export default SearchResult;
