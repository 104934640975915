import { useSelector, useDispatch } from 'react-redux';
import './Department.css';
import Product from './Product';

function Department() {
  const products = useSelector((state) => state.cart.listProducts);
  const listItems = useSelector((state) => state.cart.items);
  if (products === undefined || listItems === undefined) {
  return (
    <div className="Department">Loading</div>
    )
  }
  return (
    <div className="Department">
      {products.map(function (product, idx) {
        const item = listItems.find((item) => item.product_id === product.product_id && item.selected_size === product.selected_size);
        return (
          <Product product={product} quantity={item?.quantity || 0} key={idx} />
        );
      })}
    </div>
  );
}

export default Department;
