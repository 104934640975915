import React, { useEffect } from 'react';
import './App.css';
import Footer from './common/Footer';
import Header from './common/Header';
import Episodes from './blog/Episodes';
import Season from './blog/Season';
import Homepage from './homepage/Homepage';
import AdFree from './adfree/AdFree';
import Seti from './seti/Seti';
import Search from './search/Search';
import 'bootstrap/dist/css/bootstrap.css';
import AboutUs from './general/AboutUs';
import AboutKyle from './general/AboutKyle';
import Advertising from './general/Advertising';
import ContactUs from './general/ContactUs';
import Consulting from './consulting/Consulting';
import NotFound from './general/NotFound';
import PrivacyPolicy from './general/PrivacyPolicy';
import BlogPage from './blog/BlogPage';
import BlogRoot from './blog/BlogRoot';
import BlogTopic from './blog/BlogTopic';
import BlogTopicYear from './blog/BlogTopicYear';
import Members from './members/Members';
import Store from './store/Store';
import Sponsor from './sponsor/Sponsor';
import Sponsors from './sponsor/Sponsors';
import Checkout from './store/Checkout';
import ThankYou from './store/ThankYou';
import NavBar from './navbar/NavBar';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
const queryString = require('query-string');

const ga = "UA-88166505-1"; //process.env.REACT_APP_GA_TRACKING_ID
ReactGA.initialize(ga);

function App() {
  const location = useLocation();
  // var home_logo = "https://s3.amazonaws.com/dataskeptic.com/ds_sm.jpg"

  const parsed = queryString.parse(window.location.search);
  const q = parsed['q'];

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    console.log({ga, currentPath})
    ReactGA.pageview(currentPath);
  }, [location]);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://media.ethicalads.io/media/client/ethicalads.min.js"
        ></script>
      </Helmet>
      <NavBar />
      <Switch>
        <Route exact path="/">
          <div className="App">
            <Header />
            <Homepage />
          </div>
        </Route>
        <Route path="/about-us">
          <Header />
          <AboutUs />
        </Route>
        <Route path="/about/dataskeptic">
          <Header />
          <AboutUs />
        </Route>
        <Route path="/about/kyle-polich">
          <Header />
          <AboutKyle />
        </Route>
        <Route path="/adfree">
          <Header />
          <AdFree />
        </Route>
        <Route path="/seti">
          <Header />
          <Seti />
        </Route>
        <Route path="/ad-free">
          <Header />
          <AdFree />
        </Route>
        <Route path="/checkout">
          <Header />
          <Checkout />
        </Route>
        <Route path="/consulting">
          <Header />
          <Consulting />
        </Route>
        <Route path="/thankyou">
          <Header />
          <ThankYou />
        </Route>
        <Route path="/contact-us">
          <Header />
          <ContactUs />
        </Route>
        <Route path="/advertising">
          <Header />
          <Advertising />
        </Route>
        <Route path="/privacy-policy">
          <Header />
          <PrivacyPolicy />
        </Route>
        <Route path="/episodes/:season">
          <Header />
          <Season />
        </Route>
        <Route path="/sponsors/:sponsor">
          <Header />
          <Sponsor />
        </Route>
        <Route path="/sponsors/">
          <Header />
          <Sponsors />
        </Route>
        <Route path="/episodes">
          <Header />
          <Episodes />
        </Route>
        <Route path="/store">
          <Header />
          <Store />
        </Route>
        <Redirect
          from="/blog/episodes/2019/seq2seq-models"
          to={'/blog/episodes/2019/seq2seq'}
        />
        <Redirect
          from="/blog/episodes/2019/transfer_learning"
          to={'/blog/episodes/2019/transfer-learning'}
        />
        <Redirect
          from="/blog/episodes/2019/cross-lingual"
          to={'/blog/episodes/2019/cross-lingual-short-text-matching'}
        />
        <Redirect
          from="/epnotes/ep37_easily-fooling-deep-neural-networks.php"
          to={'/blog/episodes/2015/easily-fooling-deep-neural-networks'}
        />
        https://dataskeptic.com
        <Route path="/blog/:topic/:year/:slug">
          <BlogPage />
        </Route>
        <Route path="/blog/:topic/:year">
          <BlogTopicYear />
        </Route>
        <Route path="/blog/:topic">
          <BlogTopic />
        </Route>
        <Route path="/blog">
          <BlogRoot />
        </Route>
        <Route path="/search">
          <Header />
          <Search q={q} />
        </Route>
        <Route
          exact
          path="/feed.rss"
          render={() =>
            (window.location = 'https://dataskeptic.libsyn.com/rss')
          }
        />
        <Route
          exact
          path="/survey"
          render={() =>
            (window.location =
              'https://docs.google.com/forms/d/e/1FAIpQLSc7SbmG04zJFxrDsMH0uIm1geqKwDSJ6P3gq3oGl_9T251Pww/viewform')
          }
        />
        <Route
          exact
          path="/vote"
          render={() =>
            (window.location = 'https://forms.gle/nant6c3HyhXkCVobA')
          }
        />
        <Route
          exact
          path="/live"
          render={() =>
            (window.location = 'https://www.youtube.com/watch?v=4cFAH1Eji2U')
          }
        />
        <Route path="/members">
          <Header />
          <Members />
        </Route>
        <Route path="*">
          <Header />
          <NotFound />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}

export default App;
