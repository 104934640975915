import './Store.css';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Cart from './Cart';
import Department from './Department';
// import actions
import { setListProducts } from 'redux/cart';
// import API
import { getStoreAPI, taxAPI } from 'api/store';
import { updateTax } from '../redux/cart';

function Store() {
  const dispatch = useDispatch();

  const dept = 'main';
  const getDataFromApi = async () => {
    const products = await getStoreAPI();
    dispatch(setListProducts(products));
  };
  useEffect(() => {
    getDataFromApi(dept);
  }, [dept]);
  const cart = useSelector((state) => state.cart);
  const refreshTax = cart.refreshTax;
  useEffect(async () => {
    const { shipping_country, shipping_postal_code, shipping_region } = cart;
    if (shipping_country == "United States") {
      const resp = await taxAPI("US", shipping_region, 90008);
      const { tax_rate, shipping_taxable, valid } = resp;
      dispatch(updateTax({ tax_rate, shipping_taxable, valid }));
    } else {
      dispatch(updateTax({ tax_rate: 0.0, shipping_taxable: false, valid: false }));
    }
  }, [refreshTax]);
  return (
    <div className="Store">
      <Helmet>
        <title>Data Skeptic Store</title>
      </Helmet>
      <Container>
        <Row>
          <Col xs="12" sm="8">
            <Department />
          </Col>
          <Col xs="12" sm="4">
            <Cart />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Store;
