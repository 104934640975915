function SilverSponsor(props) {
  const sponsor = props.sponsor
  return (
    <div className="SilverSponsor">
      <div className="SilverSponsorLogoContainer"><img src={sponsor.logo} className="SilverSponsorLogo" /></div>
      <a href={sponsor.url}>{sponsor.display_url}</a>
      <p>{sponsor.description}</p>
    </div>
  );
}

export default SilverSponsor;