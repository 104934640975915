import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StripeCheckout from 'react-stripe-checkout';
// import actions
import { resetCart } from 'redux/cart';
import { postOrderAPI } from 'api/store';

const Order = () => {
  const cart = useSelector((state) => state.cart);
  const history = useHistory();
  const dispatch = useDispatch();
  const { items } = cart;

  const handleToken = async (token, addresses) => {
    const confirm = await postOrderAPI(token, addresses, cart);
    console.log({confirm})
    dispatch(resetCart());
    history.replace('/thankyou');
  };

  return (
    <div className="card border-0 order-section">
      <div className="card-2">
        <p className="card-text text-muted mt-md-4 space">YOUR ORDER</p>
        <hr className="my-2" />
      </div>
      <div className="card-body pt-0">
        {items.map((item) => (
          <>
            <div className="row justify-content-between">
              <div className="col-auto col-md-7">
                <div className="media flex-column flex-sm-row d-flex">
                  <img
                    className="img-fluid"
                    src={item.img_url}
                    width="62"
                    height="62"
                  />
                  <div className="media-body my-auto item-info">
                    <div className="row ">
                      <div className="col-auto">
                        <p className="mb-0 item-name">
                          <b>{item.name}</b>
                        </p>
                        <small className="text-muted">
                          Shipping: {item.shipping} $
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" pl-0 flex-sm-col col-auto my-auto">
                <p className="boxed-1">{item.quantity}</p>
              </div>
              <div className=" pl-0 flex-sm-col col-auto my-auto ">
                <p>
                  <b>{item.quantity * item.unit_price} $</b>
                </p>
              </div>
            </div>
            <hr className="my-2" />
          </>
        ))}

        <div className="row ">
          <div className="col">
            <div className="row justify-content-between">
              <div className="col-4">
                <p className="mb-1">
                  <b>Subtotal</b>
                </p>
              </div>
              <div className="flex-sm-col col-auto">
                <p className="mb-1">
                  <b>{cart.subtotal} $</b>
                </p>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col">
                <p className="mb-1">
                  <b>Shipping</b>
                </p>
              </div>
              <div className="flex-sm-col col-auto">
                <p className="mb-1">
                  <b>{cart.shipping} $</b>
                </p>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col">
                <p className="mb-1">
                  <b>Tax</b>
                </p>
              </div>
              <div className="flex-sm-col col-auto">
                <p className="mb-1">
                  <b>{cart.tax} $</b>
                </p>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-4">
                <p>
                  <b>Total</b>
                </p>
              </div>
              <div className="flex-sm-col col-auto">
                <p className="mb-1">
                  <b>{cart.total} $</b>
                </p>
              </div>
            </div>
            <hr className="my-0" />
          </div>
        </div>
        <div className="row mb-5 mt-4 ">
          <div className="d-grid gap-2">
            <StripeCheckout
              stripeKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
              token={handleToken}
              amount={cart.total * 100}
              name="Checkout"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
