import './AdFree.css';

import React from 'react'

function AdFree() {
    return (
        <div className="AdFree">
            <h1>About Data Skeptic</h1>
            <p>It's a podcast without ads.</p>
            <p>It's a podcast without ads.</p>
        </div>
    );
}

export default AdFree;
