import './CartItem.css';
//import CartItem from './CartItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
// import actions
import { updateItemToCart, removeItemToCart } from 'redux/cart';

function CartItem(props) {
  const dispatch = useDispatch();
  const cartItem = props.cartItem;
  var ss = cartItem.selected_size != '-' ? <span> ({cartItem.selected_size})</span> : <span></span>;
  return (
    <div className="CartItem">
      <Container>
        <Row>
          <Col xs="7">{cartItem.name} </Col>
          <Col className="CartRowCell" xs="1">
            <FontAwesomeIcon
              icon={faMinus}
              onClick={() =>
                dispatch(
                  updateItemToCart({
                    product_id: cartItem.product_id,
                    selected_size: cartItem.selected_size,
                    quantity: -1,
                  }),
                )
              }
            />
          </Col>
          <Col className="CartRowCell" xs="2">
            {cartItem.quantity}
            {ss}
          </Col>
          <Col className="CartRowCell" xs="1">
            <FontAwesomeIcon
              icon={faPlus}
              onClick={() =>
                dispatch(
                  updateItemToCart({
                    product_id: cartItem.product_id,
                    selected_size: cartItem.selected_size,
                    quantity: 1,
                  }),
                )
              }
            />
          </Col>
          <Col className="CartRowCell" xs="1">
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() =>
                dispatch(
                  removeItemToCart({
                    product_id: cartItem.product_id,
                    selected_size: cartItem.selected_size
                  }),
                )
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CartItem;
