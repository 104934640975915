import './Seti.css';
import { Container, Row, Col } from 'reactstrap';
import BlogItem from '../homepage/BlogItem';
import ListenOn from '../listen/ListenOn';
import LatestPodcast from '../homepage/LatestPodcast';

import React from 'react'

function Seti() {
  // 
  // 
    const blogStub = {
        title: 'Detecting Extra Terrestrial Communications',
        body: 'The debate of whether life exists outside earth has lingered for many years.  What data is available?  Can that data help us answer these ellusive questions?',
        timestamp: 1655653258000,
        author: {
            name: 'David Obembe',
            image_url: 'https://s3.amazonaws.com/dataskeptic.com/imgs/authors/david-obembe.png'
        },
        public_url: 'https://dataskeptic.com/blog/space/2022/detecting-extra-terrestrial-communications', 
        itunes_image: 'https://s3.amazonaws.com/dataskeptic.com/imgs/blogs/seti.jpg'
    };
    const blog1 = {
        "enclosure_url": "https://traffic.libsyn.com/secure/dataskeptic/Shakespeare-abiogenesis-exoplanets.mp3?dest-id=201630",
        "timestamp": 1443164400000.0,
        "content_encoded": "<p>Dave Spiegel joins us to discuss his paper \"Bayesian analysis of the astrobiological implications of life's early emergence on Earth\" and a few other topics.</p>",
        "link": "https://dataskeptic.com/blog/episodes/2015/shakespeare-abiogenesis-and-exoplanets",
        "seasons": [
        ],
        "itunes_image": "https://ssl-static.libsyn.com/p/assets/e/c/e/6/ece62f508912301c16c3140a3186d450/Blue_Simple_Striped_News_Podcast_Cover.jpg",
        "title": "Shakespeare, Abiogenesis, and Exoplanets"
    }
    const blog2 = {
        "enclosure_url": "https://traffic.libsyn.com/secure/dataskeptic/detecting-fast-radio-bursts-with-deep-learning.mp3?dest-id=201630",
        "timestamp": 1541142000000.0,
        "content_encoded": "<p>Gerry Zhang applies a machine learning approach known as deep learning to assist in the automated detection of fast radio bursts.  Could these be signals from extra terrestrials?</p>",
        "link": "https://dataskeptic.com/blog/episodes/2018/detecting-fast-radio-bursts-with-deep-learning",
        "seasons": [
        ],
        "itunes_image": "https://ssl-static.libsyn.com/p/assets/4/1/1/f/411fb3ae990ab9fe40be95ea3302a6a1/Blue_Simple_Striped_News_Podcast_Cover_1.jpg",
        "title": "Detecting Fast Radio Bursts with Deep Learning"
    }    
    return (
        <div className="Seti">
            <h1>Data and the Search for Extra Terrestrial Intelligence</h1>
            <p>Greetings <b><i>Big Picture Science</i></b> listeners!  Thanks for checking out Data Skeptic.</p>

            <p>We are a science podcast with an emphasis on data science, machine learning, statistics, and artificial intelligence.  Our podcast is primarily interviews with research scientists, cutting through the PR department and bringing you the unfiltered work.</p>

            <p>Check out one of the recommendations we selected for you below!</p>

            <br/>
            <h2 className="hh2">Subscribe to the Data Skeptic podcast!</h2>
            <ListenOn />
            <br/>

            <Container>
                <Row>
                    <Col xs="12" sm="6">
                        <LatestPodcast episode={blog1} />
                    </Col>
                    <Col xs="12" sm="6">
                        <LatestPodcast episode={blog2} />
                    </Col>
                </Row>
            </Container>

            <br/>
            <br/>
            <br/>
            <br/>

            <h2 className="hh2">From our blog...</h2>
            <br/>
            <BlogItem blog={blogStub} />

            <div data-ea-publisher="dataskepticcom" data-ea-type="text"></div>

            <h2 className="hh2">From our host...</h2>
            <Container>
                <Row>
                    <Col xs="12" sm="4">
                        <img className="kyleimg" src="https://s3.amazonaws.com/dataskeptic.com/imgs/2020/kyle.png" alt="Kyle Polich" />
                        <br/>
                        <i>Kyle Polich, Host of Data Skeptic</i>
                    </Col>
                    <Col xs="12" sm="8">
                        <p>The best part of working on Data Skeptic is the opportunity to speak with the researchers that are moving the discussion forward.  There's so much going on in the broader data science field as these tools find ways to impact all aspects of society.</p>
                        <p>Another great benefit of hosting this podcast is the demand it creates for me to stay current reading research literature.  I know many listeners enjoy Data Skeptic because we bring conversations instead of soundbytes, for listeners that want to have a deeper understanding of the algorithms that are changing our world.</p>
                        <p>If you're interested in taking a step beyond listening and reading some research first hand, allow me to recommend <a href="https://arxiv.org/abs/2203.11172">SETI in 2021</a> by Huston and Wright.  It's an excellent survey of contemporary research in the search for extra terrestrial intelligence.  Its a very accessible read which can route you to answer deeper questions you may have about the field.</p>
                    </Col>
                </Row>
            </Container>

            <h2 className="hh2">Subscribe!</h2>
            <ListenOn />
        </div>
    );
}

export default Seti;
