import './Homepage.css';
import React, { useState, useEffect } from 'react';
import Player from '../player/Player';
import { Helmet } from 'react-helmet';
import Guests from '../blog/Guests';
import BlogItem from './BlogItem';
import ListenOn from '../listen/ListenOn';
import LatestPodcast from './LatestPodcast';
import { Container, Row, Col } from 'reactstrap';

const def = {
  title: 'Loading...',
  duration: '',
  image: '',
  guests: '',
  content_encoded: '',
  enclosure_url: '',
};

function HomeCenter() {
  const blogStub = { title: '', episode: {} };
  const empty = {
    general: {
      latest_episode: def,
      from_the_archive: {},
      blogs: [blogStub, blogStub],
    },
  };
  const getDataFromApi = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/`);
    const general = await response.json();
    setHomepageData({ general, blogs: [blogStub, blogStub] });
    const response2 = await fetch(`${process.env.REACT_APP_API_URL}/blog`);
    const bresp = await response2.json();
    setHomepageData({ general, blogs: bresp.blogs.blogs });
  };
  const [homepageData, setHomepageData] = useState(empty);
  useEffect(() => {
    getDataFromApi();
  }, []);
  var latest_episode = homepageData.general.latest_episode;
  if (latest_episode === undefined) {
    latest_episode = def;
  }
  const from_the_archive = homepageData.general.from_the_archive;
  const title = latest_episode.title;
  //const duration = latest_episode.itunes_duration
  const image = latest_episode.itunes_image || "https://ssl-static.libsyn.com/p/assets/0/e/4/b/0e4bd71bb64c6e45/DS_-_New_Logo_assets_-_JL_DS_Logo_Stacked_-_Color_2.jpg";
  const guests = latest_episode.guests;
  const content_encoded = latest_episode.content_encoded;
  const description = latest_episode.description;
  const enclosure_url = latest_episode.enclosure_url;
  const ptitle = 'Data Skeptic';
  const blogs = homepageData.blogs;
  var blog1 = blogStub;
  var blog2 = blogStub;
  if (blogs !== undefined) {
    blog1 = homepageData.blogs[0];
    blog2 = homepageData.blogs[1];
  }
  var link = latest_episode['link'];
  return (
    <div className="HomeCenter">
      <Helmet>
        <title>{ptitle}</title>
      </Helmet>
      <Container>
        <Row>
          <Col xs="12" lg="4">
            <div className="CoverContainer"><img className="home-image" src={image} alt={ptitle} /></div>
            <div data-ea-publisher="dataskepticcom" data-ea-type="text"></div>
          </Col>
          <Col xs="12" lg="8">
            <div className="LatestEpisode">
              <div className="fotw">Latest Episode</div>
              <div className="podcast-small">
                <a href={link}>
                  <h1>{title}</h1>
                </a>
                <div
                  className="home-featured-abstract"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <Player title={title} url={enclosure_url} guests={guests} />
                <Guests guests={guests} />
                <br />
                <br />
                <ListenOn />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <hr className="hr" />

      <Container>
        <Row>
          <Col xs="12" sm="7">
            <BlogItem blog={blog1} />
            <BlogItem blog={blog2} />
          </Col>
          <Col xs="12" sm="5">
            <LatestPodcast episode={from_the_archive} />
          </Col>
        </Row>
      </Container>

      <hr className="hr" />

      <div className="sponsor-home">
        Thanks to our annual sponsors <a href="https://astrato.io/dataskeptic">Astrato</a>, <a href="https://t.clear.ml/dataskeptic">Clear ML</a>, <a href="https://neptune.ai/">Neptune.ai</a>, and <a href="https://wandb.me/dataskeptic">Weights and Biases</a>.
      </div>
    </div>
  );
}

export default HomeCenter;
