import './Sponsor.css';

import { Container, Row, Col } from 'reactstrap';

function Sponsor(props) {
  const sponsor = props.sponsor;
  if (sponsor === undefined || sponsor === null || sponsor['name'] === undefined) {
    return <div></div>;
  }
  const name = sponsor.name;
  const url = sponsor.url;
  const image_url = sponsor.logo;
  const description = sponsor.description
  const display_url = sponsor.display_url
  const layout = sponsor.layout
  if (layout == "horizontal") {
  return (
        <div className="SponsorMain">
          <Container>
            <Row>
                <Col xs="12">
                    <a href={url} className="SponsorLink"><img class="SponsorImage" src={image_url} /></a>
                </Col>
                <Col xs="12">
                    <div className="SponsorDesc">{description}</div>
                    <a href={url} className="SponsorLink">{display_url}</a>
                </Col>
            </Row>
          </Container>
        </div>
      );
  } else {
      return (
        <div className="SponsorMain">
          <Container>
            <Row>
                <Col xs="3">
                    <img class="SponsorImage" src={image_url} />
                </Col>
                <Col xs="9">
                    <a href={url}><h4>{name}</h4></a>
                    <div className="SponsorDesc">{description}</div>
                    <a href={url} className="SponsorLink">{display_url}</a>
                </Col>
            </Row>
          </Container>
        </div>
      );
  }
}

export default Sponsor;
